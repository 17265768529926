<template>
  <div>
    <div class="space-between align-center">
      <div class="align-center flex-1">
        <div class="filter-icon">
          <img src="/img/filter.svg" alt="" />
        </div>

        <div class="search-wrappper">
          <b-input-group size="sm">
          <b-form-input v-model="filter" placeholder="Search here...">
          </b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
            Clear
            </b-button>
          </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <button
        type="button"
        class="btn mulai btn-secondary font-bold"
        @click="handleNew"
        >
        <fa-icon icon="edit" class="my-icon" />&nbsp; BUAT MODUL BARU
      </button>
    </div>
    <br />
    <br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :busy="isBusy"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleEdit(row.item.id)"
        >
          <fa-icon icon="pencil-alt" class="my-icon" />&nbsp;Edit
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-danger"
          @click="handleDelete(row.item)"
        >
          <fa-icon icon="minus-circle" class="my-icon" />&nbsp;Delete
        </b-button>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      isBusy: false,
      items: [],
      fields: [
      {
        key: "no",
        label: "No"
      },
      {
        key: "name",
        label: "Nama",
        sortable: true,
        sortDirection: "desc"
      },
      {
        key: "chapter",
        label: "Bab",
        sortable: true,
        sortDirection: "desc"
      },
      {
        key: "subject",
        label: "Mapel",
        sortable: true,
        sortDirection: "desc"
      },
      {
        key: "jenjang",
        label: "Jenjang"
      },
      {
        key: "actions",
        label: "Action"
      }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      showLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn"
    })
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.push("/login");
    }
  },

  mounted() {
    this.sectionList();
  },

  methods: {
  ...mapActions({
    getSectionList: types.GET_SECTION_LIST,
    deleteSection: types.DELETE_SECTION
  }),
  handleNew() {
    this.$router.push("/dashboard/module/add");
  },
  sectionList() {
    this.level_id = this.$route.query.level ? this.$route.query.level : null;
    this.major_id = this.$route.query.major ? this.$route.query.major : null;
    this.class_id = this.$route.query.class ? this.$route.query.class : null;
    this.subject_id = this.$route.query.subject ? this.$route.query.subject : null;
    this.curriculum_id = this.$route.query.curriculum ? this.$route.query.curriculum : null;
    this.isBusy = true;
    this.getSectionList()
    .then(response => {
      var res = response;
      this.items = [];
      for (var i = 0; i < res.length; i++) {
      var dataItem = {
        no: i + 1,
        id: res[i].id,
        name: res[i].name,
        level: res[i].level,
        level_id: res[i].level_id,
        major: res[i].major,
        major_id: res[i].major_id,
        class: res[i].class,
        class_id: res[i].class_id,
        subject: res[i].subject,
        subject_id: res[i].subject_id,
        curriculum: res[i].curriculum,
        curriculum_id: res[i].curriculum_id,
        chapter: res[i].chapter,
        chapter_id: res[i].chapter_id,
        description: res[i].description,
        jenjang: res[i].major ? res[i].class+"-"+res[i].major +" "+res[i].level+" "+res[i].curriculum : res[i].class+" "+res[i].level+" "+res[i].curriculum
      };
      this.items.push(dataItem);
      }
      this.totalRows = res.length;
      this.isBusy = false;
    })
    .catch(error => {
      this.showMessageBox(error, "Failed", "success");
    });
  },
  onFiltered(filteredItems) {
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  },
  handleEdit(no) {
    this.$router.push("/dashboard/module/edit/"+no);
  },
  handleSave() {
    if(this.majorOption == 0) {
    if(this.inputData.level_id && this.inputData.class_id && this.inputData.subject_id && this.inputData.curriculum_id && this.inputData.name) {
      if(this.editData) {
      this.editSection();
      } else {
      this.addSection();
      }
    } else {
      this.showMessageBox("Data belum lengkap", "Failed", "success");
    }
    } else {
    if(this.inputData.level_id && this.inputData.major_id && this.inputData.class_id && this.inputData.subject_id && this.inputData.curriculum_id && this.inputData.name) {
      if(this.editData) {
      this.editSection();
      } else {
      this.addSection();
      }
    } else {
      this.showMessageBox("Data belum lengkap", "Failed", "success");
    }
    }
  },
  handleDelete(index) {
    this.$bvModal.msgBoxConfirm('Delete this data?', {
    title: 'Confirm',
    size: 'sm',
    buttonSize: 'sm',
    okVariant: 'danger',
    okTitle: 'YES',
    cancelTitle: 'NO',
    footerClass: 'p-2',
    hideHeaderClose: false,
    centered: true
    })
    .then(value => {
      if(value) {
      this.deleteSection({id:index.id})
        .then(response => {
        this.sectionList();
        this.showMessageBox("Deleted", "Success", "success");
        })
        .catch(error => {
        this.showMessageBox(error, "Failed", "success");
        });
      }
    })
    .catch(err => {
      this.showMessageBox(err, "Failed", "success");
    });
  },
  showMessageBox(message, title, variant) {
    this.$bvModal
    .msgBoxOk(message, {
      title: title,
      size: "sm",
      buttonSize: "sm",
      okVariant: variant,
      centered: true
    })
    .then(value => {
    })
    .catch(err => {
    });
  }
  }
};
</script>

<style>
.search-wrapper {
  width: 100%;
  max-width: 222px;
}

.filter-icon {
  margin-right: 11px;
  background-color: #e9e9e9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 7px;
}

.action-button:hover {
  background-color: #f08045;
  border: 1px solid #f08045;
}
</style>